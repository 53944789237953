import React, {useState} from 'react';
import {Icon} from 'semantic-ui-react';
import {CopyToClipboard} from 'react-copy-to-clipboard';


export default (props)=>{
    const [copied, setCopied] = useState(false);

    return <CopyToClipboard 
        text={props.value}
        color="grey"
        onCopy={()=>{ 
            setCopied(true);
            setTimeout(()=>{setCopied(false)}, 1000);
        }}
    >
        {copied ?  <Icon name="check" fitted/> : <Icon name="copy" fitted/> }
    </CopyToClipboard>
}
