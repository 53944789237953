import React from 'react';
import {CButton} from '@coreui/react'
import {Loader} from 'semantic-ui-react';
import styled from 'styled-components';

const Wrapper = styled.div`
    height:100vh;
    width:100vw;
    background: #F6F2F6;
    position:relative;
`

const Container = styled.div`
    position:absolute;
    text-align:center;
    top:40%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    max-width:500px;
    min-height:150px;
    background:#FFFFFF;
    border-radius:8px;
    border:1px solid #d4d4d4;
    padding:2em;   

    h1{
        font-size:30px;
        color: #333333;
        margin:50px 0 30px;
    }

    p {
        font-size:1.4em;
        color:#555555;
        line-height:1.4em;
        margin:0 0 2em 0;
        padding:0;
    }
`

export default (props)=>{

    const showButton = ()=>{
        if(props.disabled) return <>
                    <h1>Activating Dashboard</h1>
                    <Loader inline={true} active/>
        </>;

        return <>
            <h1>Verification Dashboard</h1>
            <p>Please login to continue.</p>
            <CButton 
                color="success" 
                onClick={props.loginFunc} 
                disabled={props.disabled} 
                size="lg"
                >Login with Active Directory</CButton>
        </>
    }

    return <Wrapper>
        <Container>
            {showButton()}
        </Container>
    </Wrapper>
}