import React, {useState, useEffect, createRef} from 'react';
import {Loader} from 'semantic-ui-react'
import {useScreenshot} from 'use-react-screenshot'
import {CCard, CCardHeader, CCardFooter, CButton, CListGroup, CListGroupItem, CCardBody, CAlert} from '@coreui/react'
import VerifyIcon from '../VerifyIcon/VerifyIcon'







export default ({loading, item, verification_id, editing, setEditing})=>{
    const ref = createRef(null)
    const getImage = ()=>{takeScreenshot(ref.current)}
    const [image, takeScreenshot] = useScreenshot()


    const isValidVerification = (item, returnColor=true)=>{
        var fields = ['device_assessment', 'true_id', 'instant_verify','otp']
        //var fields = ['device_assessment', 'true_id', 'instant_verify','era','phone_finder','iidqa','otp']
        
        var passed = true;
        var unfinished = false;
        for(var i = 0 ; i < fields.length; i++){
            if(item[fields[i]] == null) unfinished = true;
            if(!item[fields[i]]){
                passed = false
            }
        }
        var status = passed ? 'Passed' : 'Failed';
        if(unfinished) status = 'pending';
        if(item['true_id'] === false) status = 'Failed';

        var colors = {
            "Passed": "success",
            "Failed": "danger",
            "pending": "warning"
        }
        return returnColor ? colors[status] : status
    }


    useEffect(()=>{
        if(image){
            var a = document.createElement("a"); //Create <a>
            a.href = image
            a.download = "Verification_Results_"+verification_id+".png"; //File name Here
            a.click(); //Downloaded file
        }
    }, [image])

    return (<div ref={ref}>
        <CCard accentColor={isValidVerification(item)}>
        <CCardHeader className="d-flex justify-content-between align-items-center">
            <div>Verification ID: {item.verification_id}</div>
            {!loading && <CAlert color={isValidVerification(item)} size={"lg"} className="mb-0">Verification {isValidVerification(item, false)}</CAlert>}
        </CCardHeader>
        <CCardBody>
            {!loading && <CListGroup>
            <CListGroupItem color={item['device_assessment'] ? 'light' : 'danger'}>Device Assessment <VerifyIcon passed={item['device_assessment']}/> </CListGroupItem>
            <CListGroupItem color={item['true_id'] ? 'light' : 'danger'}>True ID <VerifyIcon passed={item['true_id']}/> </CListGroupItem>
            <CListGroupItem color={item['instant_verify'] ? 'light' : 'danger'}>Instant Verify <VerifyIcon passed={item['instant_verify']}/> </CListGroupItem>
            <CListGroupItem color={item['otp'] ? 'light' : 'danger'}>One Time Password <VerifyIcon passed={item['otp']}/> </CListGroupItem>
            </CListGroup>}
            {loading && <Loader inline={true} active/>}
        </CCardBody>
        <CCardFooter className="d-flex align-items-center justify-content-between">
                {false && <CButton onClick={()=>{setEditing(true)}} color="dark">Edit Verification</CButton>}
                <CButton onClick={getImage} color="primary">Download Verification Report</CButton>
        </CCardFooter>
    </CCard>
    </div>)
}


/*

            <CListGroupItem color={item['era'] ? 'light' : 'danger'}>Valid Email Address <VerifyIcon passed={item['era']}/> </CListGroupItem>
            <CListGroupItem color={item['phone_finder'] ? 'light' : 'danger'}>Phone Finder <VerifyIcon passed={item['phone_finder']}/> </CListGroupItem>
            <CListGroupItem color={item['iidqa'] ? 'light' : 'danger'}>Instant ID Q&A <VerifyIcon passed={item['iidqa']}/> </CListGroupItem>

*/