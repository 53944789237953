import Auth from './Auth'
import axios from 'axios'

const API_ENDPOINT_URL = process.env.REACT_APP_API_ENDPOINT || "";

export default class VerificationManager {

    constructor(options){
        this.updater = ()=>{}
    }

    generateLink(params){
        return this.callApiEndpoint({
            url:API_ENDPOINT_URL+"verification/generateLink",
            method:"POST",
            headers:{},
            data:params
        })
    }

    getVerificationList(offset, filter){
        var params = {
            offset: offset,
            filter: filter
        }
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"verification/list?params="+JSON.stringify(params),
            method:"GET",
            headers:{}
        })
    }

    findByVerificationId(id){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL+"verification/details?verification_id="+id,
            method:"GET",
            headers:{}
        })
    }

    saveAdminNote(data){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"save_admin_note",
            method: "POST",
            data: data
        })
    }

    updateVerification(id, verification_id, data){
        return this.callApiEndpoint({
            url: API_ENDPOINT_URL +"verification/update",
            method: "POST",
            data: {
                "verification_id": verification_id,
                "token": id,
                "data": data
            }           
        })
    }

    async callApiEndpoint(params) {
        var loginRequest = {scopes: ["Files.Read", "openid"] };
        var token;
        try{
            var accounts = Auth.getAllAccounts();
            var useAccount = false;
            accounts = (Array.isArray(accounts)) ? accounts : []; 
            for(var i = 0; i < accounts.length; i++ ) {
                if(accounts[i].homeAccountId){
                    useAccount = accounts[i];
                    break;
                }
            }
            var tokenRaw = getCookie('AD_idToken');
            token = tokenRaw ? {idToken: tokenRaw} : false;
          
            if(useAccount && !token){
                token = await Auth.acquireTokenSilent({account: useAccount}); 
                deleteCookie('AD_idToken');
                setCookie('AD_idToken', token.idToken, 600);

            }
            
            params.headers = params.headers || {};
            params.headers['Authorization'] = 'Bearer '+ token.idToken;
            params.headers['Content-Type'] = 'application/json';
            return axios(params);
        }catch(err){
           console.log(err);
           deleteCookie('AD_idToken');
           return {error: true, message: "There was an error accessing the data you requested. Please refresh the page and try your request again."}
        }
    }

}

function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

function setCookie(name, value, seconds) {
    var d = new Date;
    d.setTime(d.getTime() + seconds*1000);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function deleteCookie(name) { setCookie(name, '', -1); }


const Verify = new VerificationManager();
export { Verify };