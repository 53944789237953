import React, {useState, useEffect, createRef} from 'react'
import {CCard, CCardHeader, CButton, CDataTable, CCardBody, CRow, CCol} from '@coreui/react'
import {Verify} from '../../../services/VerificationManager'
import {Loader} from 'semantic-ui-react'
import AdminNote from './AdminNote/AdminNote';
import VerificationStepResults from './VerificationStepResults/VerificationStepResults'
import {useParams, Link} from 'react-router-dom'
import EditVerificationStepResults from './EditVerificationStepResults/EditVerificationStepResults';

export default ({closeWindow})=>{
    let {verification_id} = useParams()

    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);
    const [updateData, setUpdateData] = useState(0);

    useEffect( ()=>{
        var ignore = false;
        setLoading(true)
        Verify.findByVerificationId(verification_id).then( (data)=>{
            if(!ignore){
                setItem(data.data);
                setLoading(false);
            }
        }).catch( (e)=>{console.log(e); alert('Unexpected error. Please try again.'); setLoading(false);})

        return ()=>{ignore = true}

    }, [verification_id, updateData])




    return(<div>
        <CRow>
            <CCol md={10} className="offset-md-1">
                <CRow>
                    <CCol><CButton color="dark" to={"/verifications"} className="mb-3">Back to Verification List</CButton></CCol>
                </CRow>

                <CRow>
                    <CCol md={5}>
                        {!editing && <VerificationStepResults item={item} loading={loading} verification_id={verification_id} setEditing={setEditing}/>}
                        {editing && <EditVerificationStepResults item={item} verification_id={verification_id} updateData={updateData} setUpdateData={setUpdateData} setEditing={setEditing}/>}
                    </CCol>
                    <CCol md={7}>
                        <CCard>
                            <CCardHeader>
                                Errors
                            </CCardHeader>
                            <CCardBody>
                            <CDataTable 
                                fields={[{'key':'request_type', 'label':'Verify Step'}, 'item_name',  'item_reason_desc']}
                                items={item['error_codes']}
                                loading={loading}
                                responsive
                                striped={true}
                                scopedSlots={{
                                    'request_type': (x)=>{
                                        return <td><b>{x['request_type']}</b></td>
                                    },

                                    "item_name": (x)=>{
                                        return <td>{x['item_name']}<br/><small>{x['item_reason_code']}</small></td>
                                    }
                                }}
                            />
                            </CCardBody>
                        </CCard>
                        {false && <AdminNote admin_notes={item['admin_notes']} token={item['token']} updateData={updateData} setUpdateData={setUpdateData} /> }
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
    </div>)
}
