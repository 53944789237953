import React, {useState, useEffect} from 'react'
import {CForm, CInput, CButton, CAlert} from '@coreui/react'
import {Verify} from '../../../services/VerificationManager';

import {useHistory} from 'react-router-dom';

export default (props)=>{
    let history = useHistory()
    const [vId, setVid] = useState('')
    const [searchError, setSearchError] = useState(false);
    
    const doSearch = ()=>{
        props.setFilter(vId)
    }

    useEffect( ()=>{
        document.getElementById('verification_id_box').value = props.filter
    }, [props.filter])
    

    return(<>
        <form onSubmit={(e)=>{e.preventDefault(); doSearch();}}>
        <div className="d-flex align-items-center justify-content-between">
            <CInput 
                name="verification_id"
                id="verification_id_box"
                className="flex-fill mr-2" 
                size="lg" 
                placeholder="Enter a Verification ID"
                defaultValue={vId}
                onFocus={()=>{setSearchError(false)}}
                onChange={(e)=>{
                    setVid(e.target.value);
                }}
            />
            <CButton color="primary" size="lg" onClick={doSearch}>Search</CButton>
        </div>
        </form>
        {searchError && <div className="py-2"><CAlert color="danger">{searchError}</CAlert></div>}
    </>)
}