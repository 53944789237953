import React, {useState} from 'react'
import {CCol, CRow, CCard, CCardHeader, CCardBody, CButton, CInput, CForm, CFormGroup, CLabel, CFormText} from '@coreui/react'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard';
import {Verify} from '../../services/VerificationManager';
import {Loader} from 'semantic-ui-react'

export default (props)=>{
    
    const [vId, setVid] = useState('')
    const [email, setEmail] = useState('')
    const [verifyUrl, setVerifyUrl] = useState('')
    const [loading, setLoading] = useState(false)

    const updateVID = function(e){
        setVid(e.target.value)
    }

    const updateEmail = function(e){
        setEmail(e.target.value)
    }

    const generateLink = (e)=>{
        e.preventDefault();
        setLoading(true)
        Verify.generateLink({
            verification_id: vId,
            email:email
        }).then((data)=>{
            var url = process.env.REACT_APP_VERIFY_DOMAIN+'entry/'+data.data.token;
            setVerifyUrl(url)
            setLoading(false)
        }).catch((e)=>{
            console.log(e)
            setLoading(false)
        })
    }

    return(<>
        <CRow>
            <CCol md="6" className="offset-md-3">
                <CCard>
                    <CCardHeader>Generate New Verification Link</CCardHeader>
                    <CCardBody>
                        <p>Use the form below to generate a new verification link for a customer.</p>
                        <CForm onSubmit={generateLink} disabled={loading}>
                            <CFormGroup>
                                <CLabel>OLO Deal Number / Customer Name (Ex. 1234567 - John Doe)</CLabel>
                                <CInput name="verification_id" defaultValue={vId} onChange={updateVID} maxLength={100}/>
                                <CFormText><strong>Remember this value</strong> this is what you will use to lookup if the customer completed the verification. It is best to use an ID for the customer from Dealer Socket, CDK or Route One</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel>Customer Email</CLabel>
                                <CInput name="email" defaultValue={email} onChange={updateEmail} />
                                <CFormText>Saved as a reference, nothing is emailed to the customer</CFormText>
                            </CFormGroup>
                            <CFormGroup>
                                {!loading && <CButton type="submit" color="success" size="lg">Generate Link</CButton>}
                                {loading && <Loader inline={true} active/>}
                            </CFormGroup>
                        </CForm>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        {verifyUrl && 
        <div className="pt-3">
            <CRow>
                <CCol md="6" className="offset-md-3">
                    <CCard>
                        <CCardBody>
                            <div className="d-flex align-items-center justify-content-between p-3 verify-link">
                                <strong>{verifyUrl}&nbsp;&nbsp;&nbsp;<CopyToClipboard value={verifyUrl}/></strong>
                            </div>
                            <CButton size="lg" color="primary" href="https://bb.dealersocket.com/#/crm/search" className="d-inline-block mt-3" target="_blank">Open Dealer Socket</CButton>

                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>}
   </> )
}