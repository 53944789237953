import React, {useState, useEffect} from 'react'
import {CCard, CRow, CCol, CCardHeader, CCardBody, CPagination} from '@coreui/react'
import VerificationSearchResults from './VerificationSearchResults/VerificationSearchResults';
import VerificationDetail from './VerificationDetail/VerificationDetail';
import VerificationSearchBox from './VerificationSearchBox/VerificationSearchBox';
import {Verify} from '../../services/VerificationManager'


export default (props)=>{

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [offset, setOffset] = useState(0)
    const [filter, setFilter] = useState('')
    const [activeItem, setActiveItem] = useState(false)

    useEffect(
        ()=>{
            var ignore = false;
            setLoading(true)

            Verify.getVerificationList(offset, filter).then( (data)=>{
                if(!ignore){
                    setLoading(false)
                    if(data.data.list) {
                        var results = []
                        for(var i = 0 ; i < data.data.list.length ; i++){
                            if(data.data.list[i]){
                                results.push(data.data.list[i]);
                            }
                        }
                        setList(results)
                    }
                    else{
                        setList([])
                    }
                } 
            }).catch((e)=>{console.log(e); setLoading(false);})

            return ()=>{ignore = true}
        }
    , [offset, filter])

    return(<>
        <CRow>
            <CCol md={10} className="offset-md-1">
                <CCard>
                    <CCardBody>
                        <VerificationSearchBox filter={filter}  setFilter={setFilter}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol md={10} className="offset-md-1">
                <CCard>
                    <CCardHeader>Recent Verifications</CCardHeader>
                    <CCardBody>
                        {filter && <a onClick={()=>{setFilter('')}} className="pb-2 d-inline-block" href="#">Clear Filter</a>}
                        <VerificationSearchResults list={list} loading={loading} setActiveItem={setActiveItem}/>
                        <CPagination activePage={(offset/50)+1} pages="10" onActivePageChange={(i)=>setOffset((i*50)-50)}/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        {activeItem && <VerificationDetail item={activeItem} closeWindow={()=>{setActiveItem(false)}}/>}
    </>)
}