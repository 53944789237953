import React, {useState} from 'react'
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import GenerateLink from './components/GenerateLink/GenerateLink';
import VerificationSearch from './components/VerificationSearch/VerificationSearch';
import VerificationDetail from './components/VerificationSearch/VerificationDetail/VerificationDetail';

import {
  BrowserRouter as Router, 
  Route, 
  Switch, 
  useParams
} from 'react-router-dom';


function App() {
  return (<>
    <div className="c-app c-default-layout">

      <Router>
        <div className="c-wrapper">
          <Header />
          <div className="c-body">
            <div className="p-3">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/generate">
                  <GenerateLink/>
                </Route>
                <Route exact path="/verifications">
                  <VerificationSearch />
                </Route>
                <Route path="/verification/:verification_id">
                  <VerificationDetail />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </div>

  </>);
}

export default App;
