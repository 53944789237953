import React from 'react'
import {CCard, CCardHeader, CCardBody, CCardFooter, CButton, CRow, CCol} from '@coreui/react';
import {Link} from 'react-router-dom';


export default (props)=>{

    return(
        <div>
            <CRow>
                <CCol md="3" sm="12" className="offset-md-3">
                    <CCard>
                        <CCardHeader>Start a New Verification</CCardHeader>
                        <CCardBody>
                            <p>Generate a new link for a customer so that they can verify their identity through the LexisNexis workflow</p>
                        </CCardBody>
                        <CCardFooter>
                            <Link to="/generate"><CButton color="dark" size="lg" >Generate New Verification Link</CButton></Link>
                        </CCardFooter>
                    </CCard>
                </CCol>
                <CCol md="3" sm="12">
                    <CCard>
                        <CCardHeader>Confirm a Verification</CCardHeader>
                        <CCardBody>
                            <p>Search completed verifications to validate that your customer has completed the LexisNexis workflow successfully.</p>
                        </CCardBody>
                        <CCardFooter>
                            <Link to="/verifications"><CButton color="success" size="lg" >View Completed Verifications</CButton></Link>
                        </CCardFooter>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    )

}