import React from 'react'
import {CDataTable} from '@coreui/react'
import {Link} from 'react-router-dom'



export default (props)=>{

    const padNum = (item)=>{
        if(item < 10) return "0"+item;
        return item;
    }
    
    return(
        <CDataTable items={props.list}
        fields={
            [
                'verification_id',
                {key:'token', label:"URL"},
                'email',
                'phone',
                {'key':'otp', 'label':'Pass/Fail'},
                'entry_date'
            ]
        }
        loading={props.loading}
        scopedSlots={
            {
                "otp": (item)=>{
                    //var fields = ['device_assessment', 'true_id', 'instant_verify','era','phone_finder','iidqa','otp']
                    var fields = ['device_assessment', 'true_id', 'instant_verify','otp']
                    var passed = true;
                    var unfinished = false;
                    for(var i = 0 ; i < fields.length; i++){
                        if(item[fields[i]] == null) unfinished = true;
                        if(!item[fields[i]]){
                            passed = false
                        }
                    }
                    var status = passed ? 'Passed' : 'Failed';
                    if(unfinished) status = 'pending';
                    if(item['true_id'] === false) status = 'Failed'

                    return <td><Link to={`/verification/${item['token']}`}>{status}</Link></td>
                },
                "entry_date": (item)=>{
                    var formattedDate = ''
                    if(item.entry_date){
                        var d = new Date(item.entry_date)
                        formattedDate = padNum(d.getMonth()+1)+"/"+padNum(d.getDate())+"/"+d.getFullYear();
                    }
                    return <td>{formattedDate}</td>
                }
            }
        }
        />
    )
}