import React, {useState, useEffect} from 'react'
import {CCard, CCardHeader, CCardBody, CCardFooter, CListGroup, CListGroupItem,CButton, CInputCheckbox } from '@coreui/react'
import VerificationManager from '../../../../services/VerificationManager';





export default ({verification_id, updateData, setUpdateData, item, setEditing})=>{

    const [itemStatus, setItemStatus] = useState({});

    useEffect(()=>{
        var newItem = {
            "device_assessment": item['device_assessment'],
            "true_id": item['true_id'],
            "instant_verify": item['instant_verify'],
            /*"era": item['era'],
            "phone_finder": item['phone_finder'],
            "iidqa": item['iidqa'],*/
            "otp": item['otp']
        }
        setItemStatus(newItem);
    }, [item]);


    const cbClicked = (e)=>{
        var key = e.target.getAttribute('data-key');
        var newItem = {...itemStatus};
        newItem[key] = e.target.checked;
        setItemStatus(newItem);
    }



    const saveVerificationResults = ()=>{
        var VM = new VerificationManager();
        VM.updateVerification(verification_id, item['verification_id'], itemStatus).then(function(data){
            var updated = updateData + 1;
            setUpdateData(updated);
            setEditing(false);
        })
    }   

    const cancelVerificationEdit = ()=>{
      setEditing(false)
    }

    return(
        <CCard >
        <CCardHeader className="d-flex justify-content-between align-items-center">
            <div>Edit Verification ID: {item.verification_id}</div>
            <CButton onClick={cancelVerificationEdit} color="danger">Cancel</CButton>

        </CCardHeader>
        <CCardBody>
            <CListGroup>
                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="device_assessment" checked={itemStatus['device_assessment']} onClick={cbClicked}/> Device Assessment
                    </div>
                </CListGroupItem>
                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="true_id" checked={itemStatus['true_id']} onClick={cbClicked}/> True ID
                    </div>
                </CListGroupItem>
                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="instant_verify" checked={itemStatus['instant_verify']} onClick={cbClicked}/> Instant Verify
                    </div>
                </CListGroupItem>

                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="otp" checked={itemStatus['otp']} onClick={cbClicked}/> One Time Password
                    </div>
                </CListGroupItem>
            </CListGroup>
        </CCardBody>
        <CCardFooter className="d-flex align-items-end flex-column">
                <CButton onClick={saveVerificationResults} color="success" className="btn-lg align-items-end">Update Verification Results</CButton>
        </CCardFooter>
    </CCard>
    )
}



/*

                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="era" checked={itemStatus['era']} onClick={cbClicked}/> Valid Email Address
                    </div>
                </CListGroupItem>
                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="phone_finder" checked={itemStatus['phone_finder']} onClick={cbClicked}/> Phone Finder
                    </div>
                </CListGroupItem>
                <CListGroupItem>
                    <div className="pl-2">
                        <CInputCheckbox data-key="iidqa" checked={itemStatus['iidqa']} onClick={cbClicked}/> Instant ID Q&A
                    </div>
                </CListGroupItem>

*/