import React from 'react'
import {CHeader, CToggler, CNav, CNavItem, CNavLink, CBadge} from '@coreui/react';
import {Route,Switch, useParams} from 'react-router-dom'
import { Checkbox } from 'semantic-ui-react';

export default (props)=>{


    return(
        <CHeader className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <img src="https://pictures.dealer.com/o/offleaseonlycomgroup/0784/694d81a80aa60b64f480a39afde401e6x.jpg?impolicy=downsize&h=120" class="d-block logo-img p-2" />
                <CNav>
                    <CNavItem>
                        <CNavLink to="/">Home</CNavLink>
                    </CNavItem>
                </CNav>
            </div>
        </CHeader>
    )
}